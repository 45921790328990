<template>
    <div>
        <el-form-item label="漫画信息：">
            <el-input style="width:150px;" v-model="searchData.comicname" clearable placeholder="漫画名称"></el-input>
            <el-input style="width:100px;" v-model="searchData.comicid" clearable placeholder="漫画ID"></el-input>
            <!-- <el-input v-if="superman" style="width:200px;" v-model="searchData.authorname" clearable placeholder="作者，支持模糊搜索"></el-input> -->
            <el-select v-if="superman" v-model="searchData.uploader_uid" clearable filterable placeholder="作者，支持模糊搜索" style="width: 200px;">
                <el-option v-for="(item, index) in authorList" :key="index" :label="item.uploader_uname" :value="item.uploader_uid"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="选择平台:" v-if="level !== 5">
            <el-select v-model="searchData.productlineid" clearable multiple placeholder="选择平台" style="width: 110px;">
                <el-option v-for="(item, index) in platformList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-checkbox-group v-model="searchData.groupid">
                <el-checkbox v-show="!(check.id === 2 && level === 5)" v-for="check in searchCheckList" :label="check.id" :key="check.id">{{check.lable}}</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item>
            <el-select v-model="searchData.datetype" placeholder="统计方式" style="width: 110px;">
                <el-option v-for="(item, index) in searchTimeList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-date-picker
                :picker-options="pickerOptions"
                :clearable="false"
                v-model="dateRange"
                style="width: 260px;"
                type="daterange"
                range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期"
            >
            </el-date-picker>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="doSearchData">查询</el-button>
        </el-form-item>
        <el-form-item>
            <el-button @click="doExportData">导出</el-button>
        </el-form-item>
    </div>
</template>
<script>

import moment from 'moment';
import { mapState } from 'vuex';

export default {
    props: {
        superman: { type: Boolean },
        platformList: { type: Array },
        searchData: { type: Object, default: () => {
            return {
                comicid: null,
                comicname: null,
                authorname: null,
                startime: null,
                endtime: null,
                productlineid: null, // 平台id
                groupid: [], // 统计分组
                datetype: 1 // 时间统计方式
            };
        }}
    },
    data() {
        return {
            dateRange: null,
            searchCheckList: [{id: 2, lable: '分漫画统计'}],
            searchTimeList: [{id: 1, name: '按日'}, {id: 2, name: '按月'}, {id: 3, name: '按年'}, {id: 4, name: '汇总'}],
            pickerOptions: {},
            authorList: []
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    },
    watch: {
        'dateRange'(dateList = []) {
            this.searchData.startime = dateList ? this.formatTime(moment(dateList[0])) : '';
            this.searchData.endtime = dateList ? this.formatTime(moment(dateList[1])) : '';
        }
    },
    created() {
        this.dateRange = [this.searchData.startime, this.searchData.endtime];
        this.setPickerOptions();
        this.$api('getAuthorList', {}).then(res => {
            console.log(res);
            this.authorList = res.data;
        });
        if (this.superman) { // 超管有此权限
            this.searchCheckList.push({id: 1, lable: '分平台统计'});
            this.searchCheckList.push({id: 3, lable: '分作者统计'});
        }
    },
    methods: {
        doExportData() {
            this.$emit('doExportData');
        },
        doSearchData() {
            this.$emit('doSearchData');
        },
        setPickerOptions() {
            this.pickerOptions = {
                shortcuts: [{
                    text: '今天',
                    onClick: (picker) => {
                        const s = this.formatTime(moment(new Date()));
                        picker.$emit('pick', [s, s]);
                    }
                }, {
                    text: '昨天',
                    onClick: (picker) => {
                        const s = this.formatTime(moment(new Date()).add(-1, 'days'));
                        picker.$emit('pick', [s, s]);
                    }
                }, {
                    text: '前天',
                    onClick: (picker) => {
                        const s = this.formatTime(moment(new Date()).add(-2, 'days'));
                        picker.$emit('pick', [s, s]);
                    }
                }, {
                    text: '本周',
                    onClick: (picker) => {
                        picker.$emit('pick', [this.formatTime(moment().day('Monday')), this.formatTime(moment().day('Monday').day(+7))]);
                    }
                }, {
                    text: '上周',
                    onClick: (picker) => {
                        const s = this.formatTime(moment().week(moment().week() - 1).day('Monday'));
                        const e = this.formatTime(moment().week(moment().week() - 1).day('Monday').day(+7));
                        picker.$emit('pick', [s, e]);
                    }
                }, {
                    text: '本月',
                    onClick: (picker) => {
                        picker.$emit('pick', [this.formatTime(moment().startOf('month')), this.formatTime(moment().endOf('month'))]);
                    }
                }, {
                    text: '上月',
                    onClick: (picker) => {
                        const s = this.formatTime(moment().month(moment().month() - 1).startOf('month'));
                        const e = this.formatTime(moment().month(moment().month() - 1).endOf('month'));
                        picker.$emit('pick', [s, e]);
                    }
                }, {
                    text: '过去7天',
                    onClick: (picker) => {
                        const s = this.formatTime(moment(new Date()).add(-7, 'days'));
                        const e = this.formatTime(moment(new Date()));
                        picker.$emit('pick', [s, e]);
                    }
                }, {
                    text: '过去30天',
                    onClick: (picker) => {
                        const s = this.formatTime(moment(new Date()).add(-30, 'days'));
                        const e = this.formatTime(moment(new Date()));
                        picker.$emit('pick', [s, e]);
                    }
                }, {
                    text: '过去90天',
                    onClick: (picker) => {
                        const s = this.formatTime(moment(new Date()).add(-90, 'days'));
                        const e = this.formatTime(moment(new Date()));
                        picker.$emit('pick', [s, e]);
                    }
                }, {
                    text: '全部',
                    onClick: (picker) => {
                        picker.$emit('pick', ['1970-01-01', this.formatTime(moment(new Date()))]);
                    }
                }]
            };
        },
        formatTime(time) {
            return time.format('YYYY-MM-DD');
        }
    }
};
</script>
<style lang="scss">
</style>
