<template>
    <div class="devide-box page-container flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <top-search
                :searchData="searchData"
                :platformList="platformList"
                :superman="isSuperMan"
                @doExportData="doExportData"
                @doSearchData="doSearchData"
            >
            </top-search>
        </el-form>
        <div class="comic-dispatch-line"></div>
        <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            class="devide-box-table"
            ref="table"
            :data="tableList"
            border
            height="200"
            style="width: 100%"
            @sort-change="sortChange"
            :span-method="rowSpan"
        >
            <template slot="empty">
                <div class="emptyData">暂无数据</div>
            </template>
            <el-table-column min-width="100" sortable="custom" prop="count_date" label="统计时间" align="center" fixed></el-table-column>
            <el-table-column min-width="100" sortable="custom" v-if="dynamicShow.author" prop="author_name" label="作者" align="center" fixed></el-table-column>
            <el-table-column min-width="150" sortable="custom" v-if="dynamicShow.comicName" prop="cartoon_name" label="漫画名称" align="center" fixed>
                <template slot-scope="scope"><div style="cursor: pointer;" @click="handleSearch(scope.row)"> {{scope.row.cartoon_name}}</div></template>
            </el-table-column>
            <el-table-column min-width="100" sortable="custom" v-if="dynamicShow.productLine" prop="product_id" label="产品线" align="center"></el-table-column>
            <el-table-column prop="turnover" sortable="custom" label="供养营收" align="center"></el-table-column>
            <el-table-column prop="tradefee_rate" sortable="custom" label="渠道服务费比例" align="center"></el-table-column>
            <el-table-column prop="sharecrop_rate" sortable="custom" label="分成比例" align="center"></el-table-column>
            <el-table-column prop="sharecropping" sortable="custom" label="供养分成" align="center"></el-table-column>
        </el-table>
        <paginationPlus
            layout="total, prev, pager, next, jumper"
            :currentPage.sync="pagination.pageIndex"
            :pageSize.sync="pagination.pageSize"
            :total="pagination.total"
            @callback="pageChangeHandle"
        >
        </paginationPlus>
    </div>
</template>
<script>

import { searchMixin, paginationMixin } from '@/mixins';
import TopSearch from '../components';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
    components: {
        TopSearch
    },
    mixins: [searchMixin, paginationMixin],
    data() {
        return {
            searchData: {
                comicid: null,
                comicname: null,
                authorname: null,
                startime: null,
                endtime: null,
                productlineid: [], // 平台id
                groupid: [1, 2], // 统计分组
                datetype: 2, // 时间统计方式
                orderby: 'count_date,1' // 排序  count_date,1  1：倒序，0：正序
            },
            platformList: [],
            tableList: [],
            dynamicShow: { // 列表显隐字段
                author: false, // 作者
                productLine: false, // 产品线
                comicName: false // 漫画名
            },
            loading: false // 加载中
        };
    },
    created() {
        this.pagination.pageSize = 100;
        if (this.isSuperMan) {
            this.searchData.groupid.push(3);
        }

        this.searchData.startime = this.formatTime(moment().month(moment().month() - 1).startOf('month'));
        this.searchData.endtime = this.formatTime(moment().month(moment().month() - 1).endOf('month'));
        // this.searchData.startime = '2019-08-1';
        // this.searchData.endtime = '2019-10-1';
        this.searchHandle();
        this.getIncomeProductList();
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员 5：超级cp
        }),
        isSuperMan() { // 是否超管
            return this.level === 3;
        }
    },
    methods: {
        formatTime(time) {
            return time.format('YYYY-MM-DD');
        },
        // 导出数据
        doExportData() {
            this.searchHandle(true);
        },
        // 查询数据
        doSearchData() {
            this.searchHandle();
        },
        getProductName(row) {
            if (this.platformList.length === 0 || !row.product_id) {
                return '';
            }
            const data = this.platformList.find((item) => {
                return item.id === row.product_id;
            });
            return data ? data.name : '';
        },
        // 获取平台列表
        async getIncomeProductList() {
            const list = await this.$api('getIncomeProductList');
            if (list.data) {
                this.platformList = list.data;
            }
        },
        // 搜索, 广播查询到子组件
        searchHandle(isExport) {
            this.pagination.pageIndex = 1;
            this.getTableList(isExport);
        },
        // 查询列表
        getTableList(isExport) {
            const searchData = JSON.parse(JSON.stringify(this.searchData));
            if (searchData.productlineid) {
                searchData.productlineid = searchData.productlineid.join(',');
            }
            if (searchData.groupid) {
                searchData.groupid = searchData.groupid.join(',');
            }
            const params = { ...searchData, ...this.pagination };
            if (isExport) {
                params.isdownload = 1;
                this.downloadData(params);
                return;
            }

            this.loading = true;
            return this.$api('getRaiseIncomeDetailList', params).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { rows = [], pageIndex, pageSize, total } = res.data;
                this.tableList = rows;
                console.log(this.tableList)
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
                this.showDynamicColumn();
                this.transDataShow();
                this.loading = false;
                this.$nextTick(() => {
                    this.$refs.table.doLayout();
                });
            });
        },
        downloadData(params) { // 导出数据
            const url = '/api/income/getraiseincomedetail?';
            const nodes = [];
            Object.keys(params).forEach(key => {
                const content = params[key];
                if (!content) {
                    return;
                }
                nodes.push(`${key}=${params[key]}`);
            });
            window.open(url + nodes.join('&'));
        },
        sortChange(v) {
            this.showDynamicColumn();
            this.pagination.pageIndex = 1;
            const order = v.order;
            const prop = v.prop;
            if (order && this.checkHasSort(prop)) {
                this.searchData.orderby = prop + ',' + (order === 'ascending' ? 0 : 1);
            } else {
                this.searchData.orderby = '';
            }
            this.getTableList();
        },
        checkHasSort(prop) { // 检查是否有对应排序项目，没有则不传sort
            switch (prop) {
            case 'cartoon_name':
                return this.dynamicShow.comicName;
            case 'author_name':
                return this.dynamicShow.author;
            case 'product_id':
                return this.dynamicShow.productLine;
            default:
                return true;
            }
        },
        transDataShow() { // 列表数据转换显示
            if (this.tableList.length === 0) {
                return;
            }
            this.tableList.forEach((row) => {
                row.product_id = this.getProductName(row);
                // row.turnover = this.$utils.showIntDesc(row.turnover);
                // row.sharecropping = this.$utils.showIntDesc(row.sharecropping);
                row.tradefee_rate = (row.tradefee_rate * 100).toFixed(2) + '%';

                row.sharecrop_rate = (row.sharecrop_rate * 100).toFixed(2) + '%';
            });
        },
        showDynamicColumn() { // 动态显隐字段
            this.dynamicShow.author = this.isSuperMan && (this.searchData.authorname || this.searchData.groupid.includes(3));
            this.dynamicShow.comicName = this.searchData.comicname || this.searchData.comicid || this.searchData.groupid.includes(2);
            this.dynamicShow.productLine = this.level !== 5 && (this.searchData.productlineid.length !== 0 || this.searchData.groupid.includes(1));
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        },
        // 行列合并
        rowSpan({ row, column, rowIndex, columnIndex }) {
            const rowStyle = [0, 0];
            if (rowIndex === this.tableList.length - 1) {
                if (column.property === 'count_date') {
                    return [1, this.getColspan()];
                }
                // 漫画名称 作者 产品线
                if (column.property === 'cartoon_name' && this.dynamicShow.comicName) {
                    return rowStyle;
                }
                if (column.property === 'author_name' && this.dynamicShow.author) {
                    return rowStyle;
                }
                if (column.property === 'product_id' && this.dynamicShow.productLine) {
                    return rowStyle;
                }
            }
        },
        getColspan() {
            let i = 1;
            if (this.dynamicShow.author) {
                i++;
            }
            if (this.dynamicShow.comicName) {
                i++;
            }
            if (this.dynamicShow.productLine) {
                i++;
            }
            return i;
        },
        handleSearch(row) {
            this.searchData.comicid = row.cartoon_id
            this.doSearchData()
        }
    }
};
</script>
<style lang="scss">
    .devide-box{
        .custom_cell{
            display: inline-block;
            vertical-align: middle;
            padding: 0;
        }
        &-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                // height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
                overflow-x: auto;
            }
            tr:last-child {
                font-weight: bold;
            }
        }
        .emptyData{
            color: white;
        }
    }
</style>
